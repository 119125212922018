<template>
    <el-dialog v-loading="loader" title="Edit AdSets" :visible.sync="visible" width="500px" :before-close="handleClose">
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                <base-input class="" name="name" :rules="{ custom_required: true }" label="Name" v-model="form.name"
                    placeholder="Ad set name" label-required>
                </base-input>
                <base-input name="how_often" type="number" label="How Often" v-model="form.how_often" :rules="{ custom_required: adSet.show_how_often, numeric: true }"
                        placeholder="How Often" label-required v-if="adSet.show_how_often">
                </base-input>
                <base-input class="" name="type" label="Size" v-model="form.screen" placeholder="Type" :disabled="true">
                </base-input>
                <div class="text-right mt-5">
                    <base-button @click="handleClose" type="gray">Cancel</base-button>
                    <base-button native-type="submit" type="bari" :disabled="loader">Update</base-button>
                </div>
            </form>
        </validation-observer>
    </el-dialog>
</template>
  
<script>
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';

export default {
    props: ['visible', 'adSet','index'],
    mixins:[generateErrorMessageMixin],
    data() {
        return {
            form: {
                name: '',
                how_often: '',
                screen: ''
            },
            loader: false,
        };
    },
    watch: {
        'adSet': function (value) {
            this.form.name = value.name;
            this.form.how_often = value.how_often || '';
            this.form.screen = value.screen;
        }
    },
    methods: {
        handleClose() {
            let validation = this.$refs.formValidator;
            validation.reset();
            this.$emit('closeDialog')
        },
        async onSubmit() {
            let validation = this.$refs.formValidator;
            this.loader = true;
            try {
                let response = await this.$store.dispatch('AdsModule/_updateAdSet',{id:this.adSet.id,form:this.form});
                let {  data : {data,message } } = response;
                this.$notify.success({
                    title: 'Ad set',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$emit('updateAdSet',{data,index:this.index});
                this.handleClose();
            } catch (error) {
                this.showErrorOnForm(error,'Ad Set',validation)

            }
            this.loader = false;
        }
    },
};
</script>
  
<style scoped lang="scss"></style>
  