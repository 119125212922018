<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2 ads-set">
    <back-button class="ml-4" />
    <h1 class="ml-2 main-heading">Ad Sets</h1>
    <div class="mt-4 cardStyle">
      <el-table class="mt-4 mb-0 table-responsive table-flush" header-row-class-name="thead-light" width="100%"
        :data="ads_sets" v-loading="fetching">
        <el-table-column label="ID" prop="id" header-align="center" align="center" min-width="25x">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              {{ scope.row.id }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Name" prop="name">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="HOW OFTEN" prop="how_often">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              {{ scope.row.how_often || '-' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Code" prop="code">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              {{ scope.row.code }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Size" prop="type">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              {{ scope.row.screen }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS">
          <div slot-scope="{ $index, row }" class="d-flex">
            <router-link :to="{ name: 'admin.ads_sets.show', params: { id: row.id } }">
              <img class="eye-icon mr-2" src="/img/eye.svg" alt="" />
            </router-link>
            <img class="pointer mr-2" @click="editAdSet(row, $index)" src="/img/icons/buttons/edit.svg" alt="" />
          </div>
        </el-table-column>
      </el-table>
      <div class="d-flex justify-content-end mt-4 mr-5" v-if="total > ads_sets.length">
        <custom-pagination class="pagination-no-border" v-model="current_page" :per-page="per_page" :total="total"
          @input="changePage" />
      </div>
    </div>

    <edit-dialog :visible="showEditAdsSet" @closeDialog="closeEditDialog" :adSet="adSet" :index="index"
      @updateAdSet="updateAdSet" />
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import CustomPagination from "@/views/Components/Pagination/CustomPagination";
import BackButton from "@/components/Router/BackButton";
import EditDialog from "@/views/Components/SuperAdmin/AdsSet/EditDialog.vue";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    CustomPagination,
    BackButton,
    EditDialog,
  },
  data() {
    return {
      fetching: false,
      ads_sets: [],
      current_page: 1,
      per_page: 0,
      total: 0,
      showEditAdsSet: false,
      adSet: {},
      index: -1
    };
  },
  mounted() {
    this.getAdsSets();
  },
  methods: {
    closeEditDialog() {
      this.showEditAdsSet = false;
      this.adSet = {};
      this.index = -1;
    },
    changePage(e) {
      this.getAdsSets();
    },
    editAdSet(adSet, index) {
      this.adSet = adSet;
      this.index = index;
      this.showEditAdsSet = true;
    },
    updateAdSet(data) {
      this.getAdsSets();
    },
    async getAdsSets() {
      this.fetching = true;
      try {
        let response = await this.$store.dispatch('AdsModule/_getAdSets',{params:{page:this.current_page}});
        let {
          data: { data },
        } = response;
        this.current_page = data.current_page;
        this.per_page = data.per_page;
        this.total = data.total;
        this.ads_sets = data.data;
      } catch (error) {
        this.current_page = 1;
        this.per_page = 0;
        this.total = 0;
        this.ads_sets = [];
      }
      this.fetching = false;
    },
  },
};
</script>
<style scoped lang="scss"></style>
